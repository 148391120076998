<template>
  <div>
    <h2 class="my-6 secondary--text">
      Central registry
    </h2>
    <v-row>
      <v-col cols="5">
        <v-text-field
            append-icon="mdi-magnify"
            placeholder="Search"
            hide-details
            clearable
            outlined
            dense
            class="mb-6"
            v-model="input"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="projects"
      :items-per-page="20"                      
      :options.sync="options"
      :loading="loadingTable"
      class="tneutral-tables"
    >
        <template v-slot:item.Project_Name="{ item }">                        
            <div class="column-width font-weight-bold cursor-pointer" @click="goDetail(item)">
                {{item.Project_Name}}
            </div>
        </template>
        <template v-slot:item.Project_Owner="{ item }">                        
            <div class="column-width">
                {{item.Project_Owner}}
            </div>
        </template>
        <template v-slot:item.Project_Status="{ item }">                        
            <div class="column-width">
                {{getStatusName(item.Project_Status)}}
            </div>
        </template>
        <template v-slot:item.Project_Country="{ item }">   
            <div class="column-width">
                <img width="20" :src="item.Project_Country.CRM_URL" :alt="item.Project_Country.CRMCountry_Name"> {{item.Project_Country.CRMCountry_Name}}
            </div>
        </template>
        <template v-slot:item.actions="{ item }">   
            <v-btn outlined @click="goDetail(item)"> Go details</v-btn>
        </template>
    </v-data-table>
   <v-overlay :value="loading" >
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data: () => ({
    loading: null,
    projects: [],
    loadingTable: false,
    pagination: {limit: 20, offset: 0},
    searchInputTimeout: null,
    search: null,
    options:null, 
    headers: [
        {
            text: "PROJECT ID",
            align: "start",
            sortable: false,
            value: "Project_Id",
        },
        { text: "PROJECT NAME", value: "Project_Name" }, 
        { text: "PROJECT OWNER", value: "Project_Owner" },
        { text: "LOCATION", value: "Project_Country" },
        { text: "STATUS", value: "Project_Status" },
        { text: "ACTIVITY", value: "Project_Activity" },
        { text: "STANDARD", value: "Project_Standard" },
        { text: "PROJECT VOLUME (tons)", value: "Project_PricePerTon"},
        { text: "ACTIONS", value: "actions",sortable: false },
    ],
  }),
  computed: {
    ...mapState(["api","vars"]),
    getStatusName(){
        return vstatus => this.vars.project_status.filter(status => status.code = vstatus)[0].name
    },
    input: {
        get() {
            return this.search
        },
        set(val) {
            if (!val || val.length > 2){
                this.clearPagination();
                if (this.searchInputTimeout) {
                    clearTimeout(this.searchInputTimeout)
                }
                this.searchInputTimeout = setTimeout(() => {
                    this.pagination.offset = 0;
                    this.search = val ?  val.trim() : val;
                    if (this.search){
                        this.pagination['filter_icontains'] = encodeURIComponent(this.search);
                    } else {
                        delete this.pagination['filter_icontains']
                    }
                    this.loadProjects()
                    
                }, 400)
            }
        }
    }
  },
  mounted() {
    this.loading = true;
    this.clearPagination();
    Promise.all([this.getVars()]).then(() => {
      //this.SET_PROJECTS(response[0])
      this.loading = false;
    });
  },
  methods: {
      ...mapMutations(["SET_PROJECTS"]),
    ...mapActions(["getVars"]),
    goDetail(project){
        this.$router.push({path: `/projects/${project.Project_Id}`})
    },
    getProjects() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.api}/projects/`, { params: this.pagination})
          .then((response) => {
            //this.SET_REDUCTION_MODEL(response.data.data.projects);
            resolve(response.data.data.projects);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadProjects(){
        this.loadingTable= true;
        this.getProjects().then((response)=>{
          response.map(project=>{
              project.Project_Name = project.Project_Name.length < 40 ? project.Project_Name : project.Project_Name.slice(0,40) + '...';
              project.Project_Country = this.getCountryImg(project.Project_Country);
              project.Project_Owner = 'T-NEUTRAL';
              project.Project_Country.CRM_URL = `https://storage.googleapis.com/cloudframework-public/static/app/2021/img/flags/4x3/${project.Project_Country.CRMCountry_Iso2.toLowerCase()}.svg`
              return project
          });
          this.projects = response;
          this.loadingTable = false;
        }).catch(()=> this.loadingTable = false)
    },
    clearPagination(){
      this.pagination = {
          limit: 20, offset: 0, filters_mode: 'or',
          fields: 'Project_Name,Project_Country,Project_Status,Project_PricePerTon,Project_Id'
      };
    },
    getCountryImg(code){
        if (this.vars.countries.length == 0 && code){
            return code
        } else {
            const getCountry = this.vars.countries.filter( _p => { return _p.CRMCountry_Id ==  code});
            return getCountry.length > 0 ? getCountry[0] : [code] ;
            //return getCountry //.length > 0 ? `https://storage.googleapis.com/cloudframework-public/static/app/2021/img/flags/4x3/${getCountry[0].CRMCountry_Iso2.toLowerCase()}.svg` : code ;
        }                 
    }
  },
  watch: {
      options: {
          handler () {
              if (!this.loadingTable){
                  const { sortBy, sortDesc, page, itemsPerPage } = this.options
                  console.log("page",page);
                  console.log("page",itemsPerPage);
                  if (sortBy.length> 0){
                      this.pagination.order = sortBy[0];
                      this.pagination.dir = sortDesc[0] ? 'DESC' : 'ASC'
                  } else {
                      delete this.pagination.order;
                      delete this.pagination.dir;
                  }
                      
                  //this.loadedListFirstTime = false;
                  this.pagination.offset = 0;
                  this.loadProjects()
              }
          },
          deep: true,
      },
  }
};
</script>

<style scoped >
.cursor-pointer{
    cursor: pointer;
}
.column-width{
    width: 180px;
}

</style>

<style>
.tneutral-tables .v-data-table-header tr th {
    color: var(--tneutral-secondary-color) !important;    
    font-family: 'Work Sans', sans-serif, sans-serif;
    font-size: 14px !important;
    font-weight: 700;
    padding: 8px !important;
} 

.tneutral-tables tr td {
  font-size: 14px !important;
  padding: 12px !important;
} 
</style>