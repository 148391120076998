var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"my-6 secondary--text"},[_vm._v(" Central registry ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"mb-6",attrs:{"append-icon":"mdi-magnify","placeholder":"Search","hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)],1),_c('v-data-table',{staticClass:"tneutral-tables",attrs:{"headers":_vm.headers,"items":_vm.projects,"items-per-page":20,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Project_Name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"column-width font-weight-bold cursor-pointer",on:{"click":function($event){return _vm.goDetail(item)}}},[_vm._v(" "+_vm._s(item.Project_Name)+" ")])]}},{key:"item.Project_Owner",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"column-width"},[_vm._v(" "+_vm._s(item.Project_Owner)+" ")])]}},{key:"item.Project_Status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"column-width"},[_vm._v(" "+_vm._s(_vm.getStatusName(item.Project_Status))+" ")])]}},{key:"item.Project_Country",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"column-width"},[_c('img',{attrs:{"width":"20","src":item.Project_Country.CRM_URL,"alt":item.Project_Country.CRMCountry_Name}}),_vm._v(" "+_vm._s(item.Project_Country.CRMCountry_Name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.goDetail(item)}}},[_vm._v(" Go details")])]}}])}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }